<template>
  <div class="dpb-calendar">
    <div class="dpb-calendar__header">
      <button @click="setMonth(-1)" v-if="canGoFurtherBack" class="dpb-calendar__arrow dpb-calendar__arrow--prev" title="Poprzeni miesiąc">
        <img src="/static/imgs/caret-left.svg" alt="">
      </button>
      <button @click="setMonth(1)" class="dpb-calendar__arrow dpb-calendar__arrow--next" title="Następny miesiąc">
        <img src="/static/imgs/caret-right.svg" alt="">
      </button>
      <div class="dpb-calendar__my">
        {{currentHeader}}
      </div>
    </div>
    <div class="dpb-calendar__week">
      <div class="dpb-calendar__weekday">pon.</div>
      <div class="dpb-calendar__weekday">wt.</div>
      <div class="dpb-calendar__weekday">śr.</div>
      <div class="dpb-calendar__weekday">czw.</div>
      <div class="dpb-calendar__weekday">pt.</div>
      <div class="dpb-calendar__weekday">sob.</div>
      <div class="dpb-calendar__weekday">nd.</div>
    </div>

    <transition name="dpb-fade" mode="out-in">
      <div class="dpb-calendar__grid" v-if="monthReady" key="calList">
        <div class="dpb-calendar__day" v-for="d in days" :key="d.date" :class="{'empty': (!d.day), 'booked': (bookedDays[d.date] && bookedDays[d.date] === 1), 'before': (!bookedDays[(getSiblingDate(d.date, -1))]), 'after': (!bookedDays[(getSiblingDate(d.date, 1))])}">
          <span v-if="d.day">{{d.day}}</span>
        </div>
      </div>
      <div v-else class="dpb-calendar__loading" key="calSpinner">
        <div>
          <div class="dpb-calendar__spincont">
            <div class="dpb-calendar__spinner"></div>
              <div class="dpb-calendar__loadingtext">
                Pobieranie danych
              </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  data(){
    return{
      months: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
      date: new Date(),

      bookedDays: [],

      monthReady: false,
    }
  },
  computed: {
    currentHeader(){
      return this.months[this.date.getMonth()] + " " + this.date.getFullYear();
    },

    canGoFurtherBack(){
      const nd = new Date();
      return ((nd.getFullYear() + (nd.getMonth() / 100)) - (this.date.getFullYear() + (this.date.getMonth() / 100)) <= 0);
    },

    days(){
      let arr = [];

      //month start date
      let first_day = new Date(this.date);
      first_day.setDate(1);

      const month = first_day.getMonth();
      
      let start_day = first_day.getDay();
      //korekcja na pon na 1 miejsce
      start_day -= 1;
      if(start_day == -1) start_day = 6;

      //dołożenie pustych dni na początek tablicy 
      for(let i = 0; i < start_day; i++){
        arr.push({
          date: i.toString() + Date.now()
        });
      }

      //właściwe dni
      for(let i = start_day; first_day.getMonth() == month; i++){

        arr.push({
          date: first_day.toISOString().substring(0, 10),
          day: first_day.getDate()
        });

        first_day.setDate(first_day.getDate() + 1);
      }

      //dopchanie na koniec pustych dni
      while(arr.length % 7 != 0){
        arr.push({
          date: arr.length.toString() + Date.now()
        });
      }


      return arr;
    }
  },

  methods: {

    setMonth(dir){
      // if(!this.monthReady) return;

      const d = new Date(this.date);
      if(dir === -1){
        d.setMonth(d.getMonth() - 1);
      }
      else{
        d.setMonth(d.getMonth() + 1);
      }
      this.date = d;
      this.fetchData();
    },

    formatJSMonth(m){
      m += 1;
      if(m < 10) return '0' + m;

      return m.toString();
    },

    getSiblingDate(d, diff){
      if(!/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(d)) return 'null';
      let dd = new Date(d);
      dd.setDate(dd.getDate() + diff);
      return dd.toISOString().substring(0, 10);
    },

    async fetchData(){
      this.monthReady = false;

      let r = await fetch('/api/public/calendar?year=' + this.date.getFullYear() + '&month=' + this.formatJSMonth(this.date.getMonth()));

      if(r.status == 200){
        let data = await r.json();

        this.bookedDays = [];

        for(let i = 0; i < data.data.length; i++){
          let item = data.data[i];
          
          let start_date = new Date(item.start_date);
          start_date.setUTCHours(0,0,0,0);
          let start_date_time = start_date.getTime();

          let end_date = new Date(item.end_date);
          end_date.setUTCHours(23,59,59,999);
          let end_date_time = end_date.getTime();

          for(let j = start_date_time; j < end_date_time; j += 86400000){
            let key = (new Date(j)).toISOString().substring(0, 10);

            if(!this.bookedDays[key]){
              this.bookedDays[key] = 1;  
            }
          }
        }

        this.monthReady = true;
      }
    }
  },

  created(){
    this.fetchData();
  }
}
</script>
